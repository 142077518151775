<template>
  <div>
    <b-alert
      v-if="(serviceData.carBatteryMeasurement.value <= limitValue) && calculateAge"
      :variant="serviceData.BatteryOffer ? 'secondary': calculateAge && serviceData.carBatteryMeasurement.value >= limitValue ? 'success':'danger'"
      show
    >
      <div class="alert-body text-center py-2">
        <FeatherIcon
          icon="InfoIcon"
          size="24"
        />
        <div class="font-weight-bold font-medium-3">
          <template v-if="(Number(serviceData.carBatteryMeasurement.value) <= limitValue) && calculateAge">
            Müşteri aracının akü ölçüm seviyesi düşük.
          </template>
        </div>
        <div class="lead">
          <template v-if="(Number(serviceData.carBatteryMeasurement.value) <= limitValue) && calculateAge">
            <hr>
            <b-row class="lead">
              <b-col>
                <b>Akü Ölçüm Seviyesi</b><br> {{ serviceData.carBatteryMeasurement.value }}
              </b-col>
              <b-col>
                <b>Lastik Derinlik Ölçüm Seviyesi</b><br> {{ serviceData.carBatteryMeasurement.tire_depth }}
              </b-col>
              <b-col>
                <b>Antifriz Ölçüm Seviyesi</b><br> {{ serviceData.carBatteryMeasurement.antifreeze }}
              </b-col>
            </b-row>
            <hr>
            <div><b>Sonuç:</b> Anında değiştir.</div>
          </template>
        </div>
        <div
          class="mt-1"
        >
          <b-button
            :variant="serviceData.BatteryOffer ? 'warning' : calculateAge && serviceData.carBatteryMeasurement.value >= limitValue ? 'warning':'danger'"
            target="_blank"
            :to="serviceData.BatteryOffer ? '/battery-offers/view/'+serviceData.BatteryOffer : '/battery-offers/add-service/' + serviceData.id_com_customer + '/'+ serviceData.id +'/' + carData.id "
          >
            {{ serviceData.BatteryOffer ? 'Teklif Oluşturulmuş Görüntülemek İçin Tıklayın': 'Akü Teklifi Oluştur' }}
          </b-button>
        </div>
      </div>
    </b-alert>
    <b-alert
      v-else
      variant="success"
      show
    >
      <div class="alert-body text-center py-2">
        <FeatherIcon
          icon="CheckIcon"
          size="24"
        />
        <div class="font-weight-bold font-medium-3">
          <template v-if="serviceData.carBatteryMeasurement.value <= limitValue">
            Müşteri Aracı 2 Yaşından Küçük.
          </template>
          <template v-else>
            Akü Ölçüm Durumu Normal
          </template>
        </div>
        <hr>
        <b-row class="lead">
          <b-col>
            <b>Akü Ölçüm Seviyesi</b><br> {{ serviceData.carBatteryMeasurement.value }}
          </b-col>
          <b-col>
            <b>Lastik Derinlik Ölçüm Seviyesi</b><br> {{ serviceData.carBatteryMeasurement.tire_depth }}
          </b-col>
          <b-col>
            <b>Antifriz Ölçüm Seviyesi</b><br> {{ serviceData.carBatteryMeasurement.antifreeze }}
          </b-col>
        </b-row>
      </div>
    </b-alert>
  </div>
</template>
<script>
import {
  BAlert, BButton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'BatteryStatusCard',
  components: {
    BAlert, BButton, BRow, BCol,
  },
  data() {
    return {
      limitValue: 12.4,
    }
  },
  computed: {
    serviceData() {
      return this.$store.getters['serviceTracing/getService']
    },
    carData() {
      return this.$store.getters['cars/getCar']
    },
    calculateAge() {
      const start = Number(this.carData.myear)
      const end = this.moment().year()
      const diffInYears = end - start
      // Yaş farkını kontrol edin
      if (diffInYears > 2) {
        return true
      }
      return false
    },
  },
}
</script>
