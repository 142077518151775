<template>
  <div>
    <b-row>
      <b-col
        md="4"
      >
        <b-form-group
          label="Akü Ölçüm Değeri"
          label-for="value"
        >
          <cleave
            id="value"
            v-model="value"
            class="form-control"
            :raw="true"
            :options="options.value"
            placeholder="Giriniz"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
      >
        <b-form-group
          label="Lastik Derinlik Ölçüm Değeri"
          label-for="value"
        >
          <cleave
            id="value"
            v-model="tire_depth"
            class="form-control"
            :raw="true"
            :options="options.value"
            placeholder="Giriniz"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
      >
        <b-form-group
          label="Antifriz Ölçüm Değeri"
          label-for="value"
        >
          <cleave
            id="value"
            v-model="antifreeze"
            class="form-control"
            :raw="true"
            :options="options.value"
            placeholder="Giriniz"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <b-button
          variant="primary"
          :disabled="saveStatus || !value || !tire_depth || !antifreeze"
          @click="saveValue"
        >
          {{ saveButtonTitle }}
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'BatteryMeasurementValueInsert',
  components: {
    BRow, BCol, BButton, BFormGroup, Cleave,
  },
  data() {
    return {
      value: null,
      tire_depth: null,
      antifreeze: null,
      saveStatus: false,
      saveButtonTitle: 'Kaydet',
      options: {
        value: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    serviceData() {
      return this.$store.getters['serviceTracing/getService']
    },
  },
  methods: {
    saveValue() {
      this.saveButtonTitle = 'Kaydediliyor..'
      this.$store.dispatch('carBatteryMeasurement/dataSave', {
        id_com_service_tracing: this.serviceData.id,
        id_com_cars: this.serviceData.id_com_cars,
        id_com_customer: this.serviceData.id_com_customer,
        value: this.value,
        antifreeze: this.antifreeze,
        tire_depth: this.tire_depth,
      })
        .then(response => {
          if (response.status) {
            this.saveButtonTitle = 'Kaydedildi'
            this.$store.dispatch('serviceTracing/serviceView', this.serviceData.id)
          } else {
            this.saveButtonTitle = 'Başarısız'
          }
        })
    },
    resetTitle() {
      if (this.saveButtonTitle === 'Kaydedildi' || this.saveButtonTitle === 'Başarısız') {
        this.saveButtonTitle = 'Kaydet'
      }
    },
  },
}
</script>
