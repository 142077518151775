<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col
          v-if="customer.id_com_customer_type === '2'"
          cols="12"
          md="4"
        >
          <company-name is-readonly />
        </b-col>
        <b-col
          v-if="customer.id_com_customer_type === '2'"
          cols="12"
          md="4"
        >
          <tax-admin />
        </b-col>
        <b-col
          v-if="customer.id_com_customer_type === '2'"
          cols="12"
          md="4"
        >
          <tax-number />
        </b-col>
        <b-col
          v-if="customer.id_com_customer_type !== '2'"
          cols="12"
        >
          <tckn />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <name-surname is-readonly />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <phone is-readonly />
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <email />
        </b-col>
        <b-col v-if="customer.id_com_customer_type === '1'">
          <birthday />
        </b-col>
        <b-col v-if="customer.id_com_customer_type === '1'">
          <job is-required />
        </b-col>
        <b-col v-if="customer.id_com_customer_type === '2'">
          <sector is-required />
        </b-col>
        <b-col>
          <city />
        </b-col>
        <b-col>
          <district />
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="danger"
            :disabled="submitStatus"
            @click="submitForm"
          >
            <FeatherIcon icon="EditIcon" />
            Müşteri Bilgilerini Güncelle
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>

import { ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import CompanyName from '@/views/Customers/forms/CompanyName.vue'
import TaxNumber from '@/views/Customers/forms/TaxNumber.vue'
import TaxAdmin from '@/views/Customers/forms/TaxAdmin.vue'
import Tckn from '@/views/Customers/forms/Tckn.vue'
import NameSurname from '@/views/Customers/forms/NameSurname.vue'
import Phone from '@/views/Customers/forms/Phone.vue'
import Email from '@/views/Customers/forms/Email.vue'
import Birthday from '@/views/Customers/forms/Birthday.vue'
import Job from '@/views/Customers/forms/Job.vue'
import Sector from '@/views/Customers/forms/Sector.vue'
import City from '@/views/Customers/forms/City.vue'
import District from '@/views/Customers/forms/District.vue'

export default {
  name: 'CustomerSummary',
  components: {
    BRow,
    BCol,
    BButton,
    CompanyName,
    TaxNumber,
    TaxAdmin,
    Tckn,
    NameSurname,
    Phone,
    Email,
    Birthday,
    Job,
    Sector,
    City,
    District,
    ValidationObserver,
  },
  data() {
    return { submitStatus: false }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
    saveStatus() {
      return this.$store.getters['customers/getCustomerSaveStatus']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('customers/customerSave', this.customer)
            .then(response => {
              if (response.status) {
                this.sweetAlert({
                  icon: 'success',
                  title: 'İşlem Başarılı!',
                  text: response.message,
                  html: null,
                  buttonText: 'Kapat',
                })
              } else {
                this.sweetAlert({
                  icon: 'danger',
                  title: 'İşlem Hatası!',
                  text: response.message,
                  html: null,
                  buttonText: 'Kapat',
                })
              }
            })
        }
      })
    },
  },
}
</script>
