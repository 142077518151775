<template>
  <div>
    <b-card title="Akü Ölçüm Bilgileri">
      <battery-measurement-value-insert v-if="!serviceData.carBatteryMeasurement" />
      <battery-status-card v-if="serviceData.carBatteryMeasurement" />
    </b-card>
  </div>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import BatteryMeasurementValueInsert from '@/views/Service/Battery/ValueInsert.vue'
import BatteryStatusCard from '@/views/Service/Battery/BatteryStatus.vue'

export default {
  name: 'BatteryCard',
  components: {
    BatteryStatusCard,
    BatteryMeasurementValueInsert,
    BCard,
  },
  computed: {
    serviceData() {
      return this.$store.getters['serviceTracing/getService']
    },
    carData() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>
