<template>
  <div>
    <div v-if="!referenceData.id">
      <b-alert
        v-if="smsResult.status == null"
        variant="info"
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" />
          <p>Referans müşteri kampanyası için SMS kodu gönderin.</p>
          <b-button
            variant="info"
            size="sm"
            @click="bcsReferenceSend"
          >
            SMS Kodu Gönder
          </b-button>
        </div>
      </b-alert>
      <b-alert
        v-else-if="smsResult.status === 1"
        variant="info"
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" />
          <p>Referans müşteri kampanyası için SMS kodu gönderildi.</p>
        </div>
      </b-alert>
      <b-alert
        v-else-if="smsResult.status === 0"
        variant="danger"
        show
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" />
          <p>{{ smsResult.error }}</p>
        </div>
      </b-alert>
    </div>
    <div v-else>
      <b-alert
        show
        variant="info"
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" />
          <p>Referans müşteri kampanyasından kazanmış olduğu indirim bulundu.</p>
          <b-button
            variant="info"
            size="sm"
            :disabled="referenceData.customer_used"
            @click="bcsUseReference"
          >
            <FeatherIcon icon="CheckIcon" /> İndirimi Uygula
          </b-button>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BAlert, BButton } from 'bootstrap-vue'

export default {
  name: 'BcsCampaign',
  components: {
    BAlert,
    BButton,
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
    smsResult() {
      return this.$store.getters['bcsCampaign/smsResult']
    },
    referenceData() {
      return this.$store.getters['bcsCampaign/referenceData']
    },
  },
  methods: {
    bcsReferenceSend() {
      this.$store.dispatch('bcsCampaign/bcsReferenceSend', {
        id_com_customer: this.service.id_com_customer,
        id_com_service_tracing: this.service.id,
      })
    },
    bcsUseReference() {
      this.$store.dispatch('bcsCampaign/bcsUseReference', {
        id: this.referenceData.id,
      })
    },
  },
}
</script>
